import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PreloaderRingMin } from '../../../../components/Preload/index'
import { baseURL } from '../../../../config/baseURL'
import './index.css'


export default function AtualizarDados() {

    const [cep, setCep] = useState('')
    const [rua, setRua] = useState('')
    const [bairro, setBairro] = useState('')
    const [cidade, setCidade] = useState('')
    const [estado, setEstado] = useState('')
    const [numero, setNumero] = useState('')
    const [num_func, setNum_func] = useState('')
    const [nome, setNome] = useState('')
    const [rg, setRg] = useState('')
    const [cpf, setCpf] = useState('')
    const [dt_nasc, setDt_nasc] = useState('')
    const [estado_civil, setEstado_civil] = useState('')
    const [situac_efetivo, setSituac_efetivo] = useState('')
    const [email, setEmail] = useState('')
    const [tel1, setTel1] = useState('')
    const [tel2, setTel2] = useState('')
    const [graduacao, setGraduacao] = useState('')
    const [complemento, setComplemento] = useState('')
    const [arrayDepen, setArrayDepen] = useState([])

    const [preloadRingMinSalvar, setPreloadRingMinSalvar] = useState('d-none')

    const [classAlert, setclassAlert] = useState('alert alert-success form-group col-md-12 d-none')
    const [msgAlert, setmsgAlert] = useState('')

    const dispatch = useDispatch()
    dispatch({ type: 'MENU_ATUALIZAR_DADOS' })

    useEffect(() => {
        buscarSocio()
    }, [])

    async function buscarSocio() {
        const response = await fetch(`${baseURL}socio/${localStorage.getItem('id')}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        const respJSON = await response.json()
       
        //formatar da data
        let dataNasc = (respJSON.dt_nasc).toString()
        let dia, mes, ano
        dia = dataNasc.substring(8, 10)
        mes = dataNasc.substring(5, 7)
        ano = dataNasc.substring(0, 4)
        dataNasc = `${dia}/${mes}/${ano}`

        setNum_func(respJSON.num_func)
        setNome(respJSON.nome)
        setRg(respJSON.rg)
        setCpf(respJSON.cpf)
        setDt_nasc(dataNasc)
        setEstado_civil(respJSON.estado_civil)
        setSituac_efetivo(respJSON.situac_efetivo)
        setEmail(respJSON.email)
        setTel1(respJSON.tel1)
        setTel2(respJSON.tel2)
        setGraduacao(respJSON.graduacao)
        setRua(respJSON.socio_enderecos[0].logradouro)
        setNumero(respJSON.socio_enderecos[0].numero)
        setBairro(respJSON.socio_enderecos[0].bairro)
        setCidade(respJSON.socio_enderecos[0].cidade)
        setCep(respJSON.socio_enderecos[0].cep)
        setComplemento(respJSON.socio_enderecos[0].complemento)
        setEstado(respJSON.socio_enderecos[0].uf)
        setArrayDepen(respJSON.socio_dependentes)


    }

    async function buscarCep() {

        try {

            const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
            const respJSON = await response.json()

            if (await respJSON.erro) {

                setmsgAlert(`CEP não encontrado`)
                return setclassAlert('alert alert-danger form-group col-md-12 mt-2 d-block cadErro mt-3')
            }

            setBairro(respJSON.bairro)
            setCidade(respJSON.localidade)
            setEstado(respJSON.uf)
            setRua(respJSON.logradouro)
        } catch (error) {

            setmsgAlert(`CEP não encontrado ou inválido`)
            return setclassAlert('alert alert-danger form-group col-md-12 mt-2 d-block cadErro mt-3')
        }
    }

    async function salvarAlteracao() {
        setclassAlert('d-none')
        setPreloadRingMinSalvar('')

        if (tel1.length === 0 || rua.length === 0 || numero.length === 0 || cidade.length === 0 ||
            bairro.length === 0 || cep.length === 0 || estado.length === 0) {

            setPreloadRingMinSalvar('d-none')
            setmsgAlert('Existem campos obrigatórios em branco.')
            return setclassAlert('alert alert-danger form-group col-md-12 mt-2 ')
        }

        const response = await fetch(`${baseURL}socios-update?id=${localStorage.getItem('id')}`, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                logradouro: rua,
                uf: estado,
                cep, cidade, bairro, numero, complemento,
                email, tel1, tel2
            })
        })

        if (response.status !== 200) {
            setPreloadRingMinSalvar('d-none')
            setmsgAlert('Ocorreu algum tipo de erro interno no servidor, entre em contato com o administrador do sistema.')
            return setclassAlert('alert alert-danger form-group col-md-12 mt-2 ')
        }

        const respJSON = await response.json()

        if (respJSON.socio[0] !== 1) {
            setPreloadRingMinSalvar('d-none')
            setmsgAlert('Ocorreu algum tipo de erro durante o cadastro dos CONTATOS, verifique os dados e tente novamente.')
            return setclassAlert('alert alert-danger form-group col-md-12 mt-2 ')
        }
        if (respJSON.endereco[0] !== 1) {
            setPreloadRingMinSalvar('d-none')
            setmsgAlert('Ocorreu algum tipo de erro durante o cadastro do ENDEREÇO, verifique os dados e tente novamente.')
            return setclassAlert('alert alert-danger form-group col-md-12 mt-2 ')
        }

        setPreloadRingMinSalvar('d-none')
        setmsgAlert('Dados atualizados com sucesso.')
        setclassAlert('alert alert-success form-group col-md-8 mt-2  ')

    }

    return (
        <div className="container  ">

            <div className="row justify-content-center">

                <div className="col-md-9 p-4 rowPrincipal">
                    <div className="text-center mt-1 mb-4 col-md-12 mx-auto">
                        <h3> {nome} </h3>
                        <h5 style={{ color: '#4F4F4F' }} > {graduacao} </h5>
                    </div>

                    <div className="row py-2 justify-content-between px-2">
                        <span style={{ color: 'rgb(124, 3, 3)' }}><strong> Dados Pessoais </strong></span>
                    </div>
                    <div className="row rowContent py-2 justify-content-between px-2">
                        <span><strong> CPF </strong></span> <span style={{ color: '#4F4F4F' }}>{cpf}</span>
                    </div>
                    <div className="row rowContent py-2 justify-content-between px-2">
                        <span><strong> NF </strong></span> <span style={{ color: '#4F4F4F' }}> {num_func} </span>
                    </div>
                    <div className="row rowContent py-2 justify-content-between px-2">
                        <span><strong> RG </strong></span> <span style={{ color: '#4F4F4F' }}> {rg} </span>
                    </div>
                    <div className="row rowContent py-2 justify-content-between px-2">
                        <span><strong> Nascimento </strong></span> <span style={{ color: '#4F4F4F' }}> {dt_nasc} </span>
                    </div>
                    <div className="row rowContent py-2 justify-content-between px-2">
                        <span><strong> Estado Civil </strong></span> <span style={{ color: '#4F4F4F' }}> {estado_civil} </span>
                    </div>
                    <div className="row rowContent rowFooter py-2 justify-content-between px-2">
                        <span><strong> Situação Militar </strong></span> <span style={{ color: '#4F4F4F' }}> {situac_efetivo} </span>
                    </div>

                    <div className="row mt-3 py-2 justify-content-between px-2">
                        <span style={{ color: 'rgb(124, 3, 3)' }}><strong> Contatos </strong></span>
                    </div>
                    <div className="row rowContent py-2 justify-content-between px-2">
                        <span className="my-auto"><strong > E-mail </strong></span>   <input value={email} onChange={event => setEmail(event.target.value)} type="text" className="inputs mx-0 " />
                    </div>
                    <div className="row rowContent py-2 justify-content-between px-2">
                        <span className="my-auto"><strong > Telefone </strong></span> <input value={tel1} onChange={event => setTel1(event.target.value)} type="text" className="inputs" style={{ width: '200px' }} />
                    </div>
                    <div className="row rowContent rowFooter py-2 justify-content-between px-2">
                        <span className="my-auto"><strong > Telefone </strong></span> <input value={tel2} onChange={event => setTel2(event.target.value)} type="text" className="inputs" style={{ width: '200px' }} />
                    </div>

                    <div className="row mt-3 py-2 justify-content-between px-2">
                        <span style={{ color: 'rgb(124, 3, 3)' }}><strong> Endereço </strong></span>
                    </div>

                    {/* ENDEREÇO TELA PEQUENA */}
                    <div className="d-md-none ">
                        <div className="row rowContent rowFooter py-2 justify-content-between px-2">
                            <span><strong> CEP </strong></span>

                            <div className="mr-5">
                                <input required id="cep" onClick={() => setclassAlert('alert alert-danger form-group col-md-12 mt-2 d-none')}
                                    type="text" className="inputsCep my-auto" value={cep} style={{ width: '100px' }}
                                    aria-label="Recipient's username"
                                    aria-describedby="button-addon2" onChange={(data) => setCep(data.target.value)}
                                />
                                <button onClick={() => buscarCep()} className="btnCep " type="button" id="button-addon2"><i className="fas fa-search"></i></button>

                            </div>
                        </div>
                        <div className="row rowContent rowFooter py-2 justify-content-between px-2">
                            <span><strong> Logradouro </strong></span>  <input style={{ width: '100%' }} value={rua} onChange={event => setRua(event.target.value)} type="text" className="inputs" />
                        </div>
                        <div className="row rowContent rowFooter py-2 justify-content-between px-2">
                            <span><strong> N° </strong></span>  <input style={{ width: '80px' }} value={numero} onChange={event => setNumero(event.target.value)} type="text" className="inputs" />
                        </div>
                        <div className="row rowContent rowFooter py-2 justify-content-between px-2">
                            <span><strong> UF</strong></span>
                            <select id="uf" value={estado} onChange={event => setEstado(event.target.value)} className="inputs" style={{ width: '65px' }}>
                                <option value={estado}>{estado}</option>
                                <option value="AC">AC</option>
                                <option value="AL">AL</option>
                                <option value="AP">AP</option>
                                <option value="AM">AM</option>
                                <option value="BA">BA</option>
                                <option value="CE">CE</option>
                                <option value="DF">DF</option>
                                <option value="ES">ES</option>
                                <option value="GO">GO</option>
                                <option value="MA">MA</option>
                                <option value="MS">MS</option>
                                <option value="MT">MT</option>
                                <option value="MG">MG</option>
                                <option value="PA">PA</option>
                                <option value="PB">PB</option>
                                <option value="PR">PR</option>
                                <option value="PE">PE</option>
                                <option value="PI">PI</option>
                                <option value="RJ">RJ</option>
                                <option value="RN">RN</option>
                                <option value="RS">RS</option>
                                <option value="RO">RO</option>
                                <option value="RR">RR</option>
                                <option value="SC">SC</option>
                                <option value="SP">SP</option>
                                <option value="SE">SE</option>
                                <option value="TO">TO</option>
                            </select>
                        </div>
                        <div className="row rowContent rowFooter py-2 justify-content-between px-2">
                            <span><strong> Município </strong></span> <input style={{ width: '200px' }} value={cidade} onChange={event => setCidade(event.target.value)} type="text" className="inputs" />
                        </div>
                        <div className="row rowContent rowFooter py-2 justify-content-between px-2">
                            <span><strong> Bairro </strong></span> <input style={{ width: '200px' }} value={bairro} onChange={event => setBairro(event.target.value)} type="text" className="inputs" />
                        </div>

                        <div className="row rowContent rowFooter py-2 justify-content-between px-2">
                            <span><strong> Complemento </strong></span> <input style={{ width: '100%' }} value={complemento} onChange={event => setComplemento(event.target.value)} type="text" className="inputs" />
                        </div>
                    </div>

                    {/* ENDEREÇO TELA GRANDE */}
                    <div className="d-none d-md-block">
                        <div className="row rowContent py-2 justify-content-between px-2">
                            <span ><strong className="mr-1"> Logradouro: </strong>  <input style={{ width: '350px' }} value={rua} onChange={event => setRua(event.target.value)} type="text" className="inputs" /> </span>
                            <span ><strong className="mr-1"> N° </strong>  <input style={{ width: '80px' }} value={numero} onChange={event => setNumero(event.target.value)} type="text" className="inputs" /> </span>
                        </div>
                        <div className="row rowContent py-2 justify-content-between px-2">
                            <div>
                                <label className="mr-1 my-auto" htmlFor="uf"> <strong > UF: </strong> </label>
                                <select id="uf" value={estado} onChange={event => setEstado(event.target.value)} className="inputs" style={{ width: '65px' }}>
                                    <option value={estado}>{estado}</option>
                                    <option value="AC">AC</option>
                                    <option value="AL">AL</option>
                                    <option value="AP">AP</option>
                                    <option value="AM">AM</option>
                                    <option value="BA">BA</option>
                                    <option value="CE">CE</option>
                                    <option value="DF">DF</option>
                                    <option value="ES">ES</option>
                                    <option value="GO">GO</option>
                                    <option value="MA">MA</option>
                                    <option value="MS">MS</option>
                                    <option value="MT">MT</option>
                                    <option value="MG">MG</option>
                                    <option value="PA">PA</option>
                                    <option value="PB">PB</option>
                                    <option value="PR">PR</option>
                                    <option value="PE">PE</option>
                                    <option value="PI">PI</option>
                                    <option value="RJ">RJ</option>
                                    <option value="RN">RN</option>
                                    <option value="RS">RS</option>
                                    <option value="RO">RO</option>
                                    <option value="RR">RR</option>
                                    <option value="SC">SC</option>
                                    <option value="SP">SP</option>
                                    <option value="SE">SE</option>
                                    <option value="TO">TO</option>
                                </select>
                            </div>
                            <span><strong className="mr-1 my-auto"> Município: </strong> <input style={{ width: '200px' }} value={cidade} onChange={event => setCidade(event.target.value)} type="text" className="inputs" /> </span>
                            <span><strong className="mr-1 my-auto"> Bairro: </strong> <input style={{ width: '200px' }} value={bairro} onChange={event => setBairro(event.target.value)} type="text" className="inputs" /></span>
                        </div>
                        <div className="row rowContent py-2 justify-content-between px-2">

                            {/*BUSCAR CEP*/}
                            <div className=" py-1">
                                <span><strong className="mr-1"> CEP: </strong> </span>

                                <input required onClick={() => setclassAlert('alert alert-danger form-group col-md-12 mt-2 d-none')}
                                    type="text" className="inputsCep my-auto" value={cep} style={{ width: '100px' }}
                                    aria-label="Recipient's username"
                                    aria-describedby="button-addon2" onChange={(data) => setCep(data.target.value)}
                                />

                                <button onClick={() => buscarCep()} className="btnCep" type="button" id="button-addon2">
                                    <i className='fas fa-search'></i>
                                </button>

                            </div>

                            <span ><strong className="mr-1"> Complemento: </strong> <input style={{ width: '350px' }} value={complemento} onChange={event => setComplemento(event.target.value)} type="text" className="inputs" /></span>

                        </div>
                    </div>

                    {/*DEPENDENTES*/}
                    <div className="row mt-3 py-2 justify-content-between px-2">
                        <span style={{ color: 'rgb(124, 3, 3)' }}><strong> Dependentes </strong></span>
                    </div>
                    {
                        arrayDepen.map(depen => {
                            return (
                                <div key={depen.id} className="row rowContent py-2 justify-content-between px-2">
                                    <span><strong> {depen.nome} </strong></span> <span style={{ color: '#4F4F4F' }}>{depen.parentesco}</span>
                                </div>
                            )
                        })
                    }

                    <div className={classAlert} role="alert" >
                        <strong> {msgAlert}</strong>
                    </div>

                    <button onClick={salvarAlteracao} type="button" className="btn btn-success mt-3 btn-lg btn-block" >SALVAR <span className={preloadRingMinSalvar}> <PreloaderRingMin /> </span></button>

                </div>

            </div>

        </div>


    )


}