import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { baseURL } from '../../../config/baseURL'
import saveAs from 'file-saver'

export default function AtaReunioes() {

    const dispath = useDispatch()
    dispath({ type: 'MENU_ATAS_REUNIOES' })

    const [arrayBalancete, setArrayBalancete] = useState([])

    useEffect(() => {
        getDadosBalancetes()
    }, [])

    async function baixarBalancete(nome_arquivo) {

        const response = await fetch(`${baseURL}ata-reuniao?nome_arquivo=${nome_arquivo}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })

        const blobFile = await response.blob()
        let fileName

        switch (blobFile.type) {
            case 'application/pdf':
                fileName = 'BalanceteCaxias.pdf'
                break;
            case 'image/jpeg':
                fileName = 'BalanceteCaxias.jpeg'
                break;
            case 'image/pjpeg':
                fileName = 'BalanceteCaxias.pjpeg'
                break;
            case 'image/png':
                fileName = 'BalanceteCaxias.png'
                break;
            default:
                fileName = 'BalanceteCaxias'
                break;
        }
        saveAs(blobFile, fileName)
    }

    async function getDadosBalancetes() {

        const response = await fetch(`${baseURL}ata-reuniao-index-all`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        const respJSON = await response.json()
        setArrayBalancete(respJSON)
    }

    return (
        <div className="container">

            <div className="row  justify-content-center">
                <div className="col-md-10 py-5 px-3  rowPrincipal">

                    <div className="col-md-7 text-justify ">
                        <h5> <strong>Reuniões, </strong> </h5>
                        <p className="text-dark"> Confira as atas das reuniões de acordo com a data.</p>
                    </div>

                    <div className="div" style={{ overflow: 'auto' }}>
                        <table className="table table-hover text-center" style={{ overflow: 'auto', borderStyle: 'solid', borderWidth: '5px', borderColor: 'rgb(124, 3, 3)' }}>
                            <thead>
                                <tr className="text-light" style={{ overflow: 'auto', backgroundColor: 'rgb(124, 3, 3)' }}>
                                    <th>Data</th>
                                    <th>Assunto</th>
                                    <th>Descrição</th>
                                    <th>Baixar</th>
                                </tr>
                            </thead>
                            <tbody className="table-secondary table-bordered">
                                {arrayBalancete.map((balancete) => {
                                    return (
                                        <tr key={balancete.id} >
                                            <td className="p-auto" > {balancete.data} </td>
                                            <td>{balancete.assunto}</td>
                                            <td>{balancete.descricao}</td>
                                            <td> <button onClick={() => baixarBalancete(balancete.nome_arquivo)} className="btn btn-info text-light"> <i className="fas fa-download "></i> </button>  </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>

                    </div>

                </div>
            </div>

        </div>
    )
}