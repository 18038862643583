import React, { useState, useEffect } from 'react'

export default function Home() {

    const [horario, setHorario] = useState('')

    useEffect(() => {

        const data = new Date()
        let hora = data.getHours()
        let minutos = data.getMinutes()
        let segundos = data.getSeconds()
        segundos = ('00' + segundos).slice(-2)
        minutos = ('00' + minutos).slice(-2)
        hora = ('00' + hora).slice(-2)
        const horaCompleta = `${hora}:${minutos}:${segundos}`

        if (horaCompleta >= '00:00:00' && horaCompleta < '12:00:00') {
            setHorario('Bom dia')
        } else {
            if (horaCompleta >= '12:00:00' && horaCompleta < '18:00:00') {
                setHorario('Boa tarde')
            } else {

                if (horaCompleta >= '18:00:00' && horaCompleta <= '23:59:59') {
                    setHorario('Boa noite')
                }
            }
        }
    }, [])

    return (

        <div className="container">
            <div className="row text-center justify-content-center">
                <div className="col-md-9 ">

                    <h5>{horario},  <strong> {localStorage.getItem('graduacao')} {localStorage.getItem('nome')}.</strong></h5>
                    <h6 className="ml-3 ">Seja bem vind{localStorage.getItem('sexo') === 'M' ? 'o' : 'a'}, este é o espaço que
             o <strong>CAXIAS E.C </strong>  oferece para facilitar a vida do associado.</h6>
                    <h6 className="ml-3" >Aqui {localStorage.getItem('sexo') === 'M' ? 'o Sr.' : 'a Sra.'} pode
                    atualizar seus dados, visualizar atas de reunião, balancetes financeiros e novidades do clube,
            além de poder realizar alguns tipos de solicitações e sugestões ao clube.</h6>

                </div>
            </div>

            <div className="col-lg-12 mt-2 carrousel p-0">
                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    </ol>

                    <div className="carousel-inner rounded-carousel">

                        <div className="carousel-item active">

                            <img src='https://www.caxiasesporteclube.com.br/imagens/imgSliderHome/anoNovoCaxias.jpg' className="d-block w-100 "
                                alt="Aniversário Caxias" />

                        </div>

                        <div className="carousel-item">
                            <img src='https://www.caxiasesporteclube.com.br/imagens/imgSliderHome/quataDeFinal.jpg' className="d-block w-100 "
                                alt="Aniversário Caxias" />
                        </div>

                        <div className="carousel-item ">

                            <img src='https://www.caxiasesporteclube.com.br/imagens/imgSliderHome/salaoFesta.jpg' className="d-block w-100 "
                                alt="Aniversário Caxias" />

                        </div>

                    </div>

                    <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>

                    <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>

                </div>
            </div>

            <div className="mt-2 text-justify d-md-none d-lg-none d-xl-none">
                <span className="text-dark" style={{ color: 'black', fontSize: '10pt' }}>Com a configuração de rotação ligada, gire o celular na horizontal para ampliar o quadro de avisos.</span>
            </div>

        </div>


    )

}