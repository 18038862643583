import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { baseURL } from '../../../config/baseURL'

import { PreloaderRingMin } from '../../../components/Preload/index'

export default function Solicitacoes() {

    useEffect(() => {
        buscarSolicitaoes()
    }, [])

    const dispatch = useDispatch()
    dispatch({ type: 'MENU_SOLICITACOES' })

    const [assunto, setAssunto] = useState('')
    const [prioridade, setPrioridade] = useState('')
    const [conteudo_solic, setConteudo_solic] = useState('')
    const [alertUserSenha, setAlertUserSenha] = useState('d-none')
    const [msgErro, setMsgErro] = useState('')
    const [preload, setPreload] = useState('d-none')
    const [displaySolicit, setdisplaySolicit] = useState('accordion d-none')
    const [arraySolicitacoes, setArraySolicitacoes] = useState([])

    function limparDados() {
        setAssunto('')
        setConteudo_solic('')
        setPrioridade('')
    }

    async function enviarSolicitacao(event) {
        event.preventDefault()
        setPreload('mx-2 my-auto')

        if (assunto.length === 0 || conteudo_solic.length === 0) {
            setPreload('d-none')
            setMsgErro('Existem campos em branco que devem ser preenchidos.')
            return setAlertUserSenha('alert alert-danger col-md-12 mt-1 mb-1')
        }

        if (prioridade.length === 0) {
            setPreload('d-none')
            setMsgErro('Selecione o tipo de prioridade da solicitação.')
            return setAlertUserSenha('alert alert-danger col-md-12 mt-1 mb-1')
        }

        const response = await fetch(`${baseURL}solicitacoes?socio_id=${localStorage.getItem('id')}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                assunto,
                prioridade,
                conteudo_solic,
                status: 'Pendente'
            })
        })

        const respJSON = await response.json()

        if (respJSON.erro) {
            setPreload('d-none')
            setMsgErro(respJSON.erro)
            return setAlertUserSenha('alert alert-danger col-md-12 mt-1 mb-1')
        }
        if (response.status !== 200) {
            setPreload('d-none')
            setMsgErro('Ocorreu algum erro interno, entre em contato com a administração do clube.')
            return setAlertUserSenha('alert alert-danger col-md-12 mt-1 mb-1')
        }

        limparDados()
        setMsgErro('Solicitação enviada com sucesso, faremos de tudo para responder o mais rápido possível.')
        setPreload('d-none')
        setAlertUserSenha('alert alert-success col-md-12 mt-1 mb-1')
        buscarSolicitaoes()

    }

    async function buscarSolicitaoes() {

        const response = await fetch(`${baseURL}solicitacoes?socio_id=${localStorage.getItem('id')}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })

        const respJSON = await response.json()

        if (respJSON.erro) {
            setArraySolicitacoes([])
            setdisplaySolicit('d-none')
        } else {
            setArraySolicitacoes(respJSON)
            setdisplaySolicit('accordion')
        }
    }

    return (
        <div className="container">

            <div className="row  justify-content-center">
                <div className="col-md-10 py-5 px-3  rowPrincipal">

                    <div className="col-md-12 text-center ">
                        <h5> <strong>Solicitações ou Sugestões ao Clube, </strong></h5>
                        <p className="text-dark"> Realize e acompanhe aqui as suas solicitações ou sugestões ao clube.</p>
                    </div>

                    <form onSubmit={enviarSolicitacao} >
                        <div className="row">

                            <div className="col-md-9 my-1">
                                <input type="text" className="form-control mb-n1" placeholder="Assunto" maxLength="50"
                                    value={assunto} onChange={event => setAssunto(event.target.value)}
                                    onClick={() => setAlertUserSenha('d-none')} />
                                <small className="ml-1 text-secondary" >Máximo 50 caracteres</small>
                            </div>

                            <div className="col my-1">
                                <select className="form-control form-control" value={prioridade}
                                    onChange={event => setPrioridade(event.target.value)}
                                    onClick={() => setAlertUserSenha('d-none')}>
                                    <option value="" >Prioridade</option>
                                    <option value="Baixa" >Baixa</option>
                                    <option value="Normal" >Normal</option>
                                    <option value="Alta" >Alta</option>
                                </select>
                            </div>
                        </div>

                        <div className=" my-2">
                            <textarea className="form-control mb-n1" name="" id="" cols="30" rows="4"
                                placeholder="Digite aqui sua solicitação..." value={conteudo_solic} maxLength="500"
                                onChange={event => setConteudo_solic(event.target.value)}
                                onClick={() => setAlertUserSenha('d-none')}></textarea>
                            <small className="ml-1 text-secondary" >Máximo 500 caracteres</small>
                        </div>

                        <div className="row mx-1">
                            <button type="submit" className="btn text-light my-1 mr-2 btn-sm" style={{ backgroundColor: 'rgb(124, 3, 3)' }} >Enviar</button>
                            <button onClick={limparDados} type="button" className="btn btn-secondary my-1 btn-sm" >Limpar</button>
                            <div className={preload}>
                                < PreloaderRingMin />
                            </div>
                        </div>

                    </form>

                    <div className={alertUserSenha} role="alert">
                        <strong>  {msgErro} </strong>
                    </div>

                    <div className={displaySolicit} id="accordionExample" style={{ overflow: 'auto' }} >
                        <div className="col-md-12 text-center mt-4 mb-3">
                            <h5> <strong>Minhas Solicitações </strong></h5>
                        </div>

                        <small>Clique no <i className="fas fa-eye text-dark"></i> para visualizar a solicitação.</small>

                        {
                            arraySolicitacoes.map((solicit) => {
                                if (arraySolicitacoes.length !== 0) {
                                    const timeSolic = new Date(solicit.createdAt);
                                    const timeResp = new Date(solicit.updatedAt);

                                    let anoSolic = timeSolic.getFullYear()
                                    let mesSolic = timeSolic.getMonth() + 1
                                    let diaSolic = timeSolic.getDate()
                                    let horaSolic = timeSolic.getHours()
                                    let minSolic = timeSolic.getMinutes()
                                    minSolic = ('00' + minSolic).slice(-2)
                                    horaSolic = ('00' + horaSolic).slice(-2)
                                    diaSolic = ('00' + diaSolic).slice(-2)
                                    mesSolic = ("00" + mesSolic).slice(-2)

                                    let anoResp = timeResp.getFullYear()
                                    let mesResp = timeResp.getMonth() + 1
                                    let diaResp = timeResp.getDate()
                                    let horaResp = timeResp.getHours()
                                    let minResp = timeResp.getMinutes()
                                    minResp = ('00' + minResp).slice(-2)
                                    horaResp = ('00' + horaResp).slice(-2)
                                    diaResp = ('00' + diaResp).slice(-2)
                                    mesResp = ("00" + mesResp).slice(-2)

                                    let dataSolicit = `${diaSolic}/${mesSolic}/${anoSolic}`
                                    let horarioSolic = `${horaSolic}:${minSolic}`
                                    let dataResp = `${diaResp}/${mesResp}/${anoResp}`
                                    let horarioResp = `${horaResp}:${minResp}`

                                    let display = ''
                                    let styleBadge = ''
                                    solicit.status === 'Respondida' ? display = 'block' : display = 'none'
                                    solicit.status === 'Respondida' ? styleBadge = 'badge badge-success' : styleBadge = 'badge badge-secondary'

                                    return (

                                        <div className="card" key={solicit.id}>

                                            <div className="row px-2" id={`heading${solicit.id}`}>
                                                <table className="table m-0 " style={{ backgroundColor: 'rgb(124, 3, 3)' }} >
                                                    <tbody>
                                                        <tr className="text-center text-light">
                                                            <td className="pl-3 text-left ">{solicit.assunto} </td>
                                                            <td className="pl-0 text-right" >
                                                                <span className={styleBadge}>{solicit.status}</span>
                                                                <button className="btn ml-3 p-0 mr-3" type="button" data-toggle="collapse"
                                                                    data-target={`#collapse${solicit.id}`} aria-expanded="true" aria-controls={`collapse${solicit.id}`}>
                                                                    <i className="fas fa-eye text-light"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                            </div>

                                            <div id={`collapse${solicit.id}`} className="collapse" aria-labelledby={`heading${solicit.id}`} data-parent="#accordionExample">
                                                <div className="card-body">
                                                    <p className="my-1 text-dark" > <strong style={{ color: 'black', fontWeight: '600' }}  > Data:</strong> {dataSolicit}</p>
                                                    <p className="my-1 text-dark" > <strong style={{ color: 'black', fontWeight: '600' }}  > Hora:</strong> {horarioSolic}h</p>
                                                    <p className="my-1 text-dark" > <strong style={{ color: 'black', fontWeight: '600' }}  > Prioridade:</strong> {solicit.prioridade} </p>
                                                    <p className="my-1 text-dark" > <strong style={{ color: 'black', fontWeight: '600' }}  > Status:</strong> {solicit.status} </p>
                                                    <p className="my-1 text-dark text-justify" > <strong style={{ color: 'black', fontWeight: '600' }} > Assunto:</strong> {solicit.assunto} </p>
                                                    <p className="my-1 text-dark text-justify" > <strong style={{ color: 'black', fontWeight: '600' }} > Conteúdo:</strong> {solicit.conteudo_solic} </p>

                                                    <div className="col-md-12  border-top border-dark my-3 pt-2 px-0" style={{ display: display }}>
                                                        <div className="col-md-12 text-center">  <strong style={{ color: 'black', fontWeight: '600', fontSize: '18px' }}  >
                                                            <i className="fas fa-reply-all"></i> Resposta</strong>
                                                        </div>

                                                        <p className="my-1 text-dark" > <strong style={{ color: 'black', fontWeight: '600' }}  > Data:</strong> {dataResp} </p>
                                                        <p className="my-1 text-dark" > <strong style={{ color: 'black', fontWeight: '600' }}  > Hora:</strong> {horarioResp}h</p>
                                                        <p className="my-1 text-dark text-justify" > <strong style={{ color: 'black', fontWeight: '600' }} > Conteúdo:</strong> {solicit.conteudo_resp} </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                } else {
                                    return (
                                        <> </>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}