import React, { useState, useEffect } from 'react'
import { Provider } from 'react-redux'

import store from '../../store'

import { authJWT } from '../../auth/auth'

//importação do subRoutes
import SubRoutes from '../../subRoutes'

import MenuSuspenso from '../../components/MenuSuspenso/index'
import MenuLateral from '../../components/MenuLateral/index'

import './index.css'

export default function PainelHome({ history }) {

    //VERIFICAÇÃO DO TOKEN
    async function autentic() {
        if (!await authJWT()) {
            alert('Usuário não autenticado')
            return history.push('/')
        }
    }

    const [displayBtn, setDisplayBtn] = useState('none')

    window.onscroll = () => mostrarBtn()

    function mostrarBtn() {

        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 200) {
            setDisplayBtn('')
        } else {
            setDisplayBtn('none')
        }
    }

    useEffect(() => {
        autentic()
    }, [])

    return (
        <Provider store={store}>

            <div id="topo" className="wrapper">

                <MenuLateral />

                <div id="content">

                    <MenuSuspenso />
                    <SubRoutes />


                </div>

            </div>

            <a href="#topo" className='stileBtn' style={{ display: displayBtn }} >
                <span className="spanBtn">
                    <strong> <i className="fas fa-chevron-up"></i> </strong>
                </span>
            </a>

        </Provider>
    )
}