import React, { useState } from 'react';
import '../index.css'
import { baseURL } from '../../../config/baseURL'

//Import de imagens
import imgLogo from '../../../assets/img-login/imgLogo45x45.png'
import imgLogosm from '../../../assets/img-login/imgLogo32x32.png'

//Componente Preload
import { PreloaderRing } from '../../../components/Preload/index'

export default function AlterarSenhaLogin({ history }) {

    const [displayBtnEntr, setDisplayBtnEntr] = useState("btn btn-primary btn-lg float-right d-none")
    const [displayBtnAtualiz, setDisplayBtnAtualiz] = useState("btn btn-danger btn-lg float-right")
    const [senha, setSenha] = useState('')
    const [repetSenha, setRepetSenha] = useState()
    const [preloadRing, setPreloadRing] = useState({ display: 'none' })
    const [alertUserSenha, setAlertUserSenha] = useState('d-none')
    const [msgErro, setMsgErro] = useState('')
    const [typeInput, setTypeInput] = useState('password')
    const [typeInput2, setTypeInput2] = useState('password')

    async function enviarNovaSenha(event) {
        event.preventDefault()

        setPreloadRing({ display: 'block' })

        if (senha !== repetSenha) {
            setPreloadRing({ display: 'none' })
            setMsgErro('A senha e a confirmação da senha devem ser iguais.')
            return setAlertUserSenha('alert alert-danger col-md-12 mt-4 mb-1')
        }

        if (senha.length < 8) {
            setPreloadRing({ display: 'none' })
            setMsgErro('A senha deve conter no mínimo 8 dígitos.')
            return setAlertUserSenha('alert alert-danger col-md-12 mt-4 mb-1')
        }

        const response = await fetch(`${baseURL}socio-update-senha?id=${localStorage.getItem('id')}`, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                senha,
                force_senha: 'N'
            })
        })

        const resJSON = await response.json()

        if (resJSON === 0) {
            setPreloadRing({ display: 'none' })
            setMsgErro('Ocorreu algum erro durante a atualizaçao, tente novamente.')
            return setAlertUserSenha('alert alert-danger col-md-12 mt-4 mb-1')
        }

        setPreloadRing({ display: 'none' })
        setMsgErro('Senha alterada com sucesso!')
        setAlertUserSenha('alert alert-success col-md-12 mt-4 mb-1')
        setDisplayBtnEntr("btn btn-primary btn-lg float-right")
        setDisplayBtnAtualiz('d-none')
    }


    return (
        <div className="containerEdit">
            <div className="row">
                <div className="col-md-12">

                    {/*TITULO PARA TELAS PEQUENAS*/}
                    <div className="col-md-12 d-lg-none d-md-none d-sm-block">
                        <h5 className="text-center text-white mb-4">
                            <img className="img-fluid" src={imgLogosm} alt="" />
                            <strong className="mx-1"> ALTERAR SENHA</strong>
                            <img className="img-fluid" src={imgLogosm} alt="" />
                        </h5>
                    </div>
                    {/*TITULO PARA TELAS GRANDES*/}
                    <div className="col-md-12 d-none d-md-block">
                        <h2 className="text-center text-white mb-4">
                            <img className="img-fluid" src={imgLogo} alt="" />
                            <strong className="mx-3"> ALTERAR SENHA</strong>
                            <img className="img-fluid" src={imgLogo} alt="" />
                        </h2>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mx-auto">

                            <div className="card rounded-5">

                                <div className="card-header">
                                    <p className="mb-0 text-light text-justify" style={{ fontSize: '14px' }}>Verificamos que sua senha foi alterada pela administração
                                    do clube, por segurança altere a senha para uma senha de sua preferencia com no mínimo 8 dígitos</p>
                                </div>

                                <div className="card-body">

                                    <form onSubmit={enviarNovaSenha} className="needs-validation"
                                        id="formLogin" method="POST">
                                        <div className="form-group">

                                            <label className="text-light" htmlFor="validationCustom03">Nova Senha</label>
                                            <div className="input-group">
                                                <input type={typeInput}
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    value={senha}
                                                    required
                                                    onChange={event => setSenha(event.target.value)}
                                                    onClick={() => {
                                                        setAlertUserSenha('alert alert-danger col-md-12 mt-3 d-none mr-5')
                                                    }}
                                                />

                                                <div className="input-group-append">
                                                    <button className="btn" type="button" style={{ backgroundColor: '#e8f0fe' }} id="basic-addon2"
                                                        onClick={() => {
                                                            typeInput === 'password' ? setTypeInput('text') : setTypeInput('password')

                                                        }}
                                                    >
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="text-light" htmlFor="senhaInput" >Confirmação da Senha</label>
                                            <div className="input-group">
                                                <input type={typeInput2}
                                                    className="form-control"
                                                    id="senhaInput"
                                                    value={repetSenha}
                                                    required
                                                    onChange={event => { setRepetSenha(event.target.value) }}
                                                    onClick={() => {
                                                        setAlertUserSenha('alert alert-danger col-md-12 mt-3 d-none mr-5')
                                                    }}
                                                />

                                                <div className="input-group-append">
                                                    <button className="btn" type="button" style={{ backgroundColor: '#e8f0fe' }} id="basic-addon2"
                                                        onClick={() => {
                                                            typeInput2 === 'password' ? setTypeInput2('text') : setTypeInput2('password')

                                                        }}
                                                    >
                                                        <i class="fas fa-eye"></i> 
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-2 align-items-center justify-content-end px-3">
                                            <div style={preloadRing} > <PreloaderRing /> </div>
                                            <div className=" ml-3 mr-n3 p-0 align-self-center">    <button onClick={() => setAlertUserSenha('d-none')} type="submit" className={displayBtnAtualiz} id="btnLogin">  Atualizar Senha</button> </div>
                                            <div className=" ml-3 p-0 align-self-center">    <button onClick={() => history.push('/painel/home')} type="button" className={displayBtnEntr} id="btnLogin">  Entrar</button> </div>
                                        </div>
                                    </form>
                                    <div className={alertUserSenha} role="alert">
                                        <strong>  {msgErro} </strong>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </div>

    )

}