import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import '../../pages/Painel/index.css'
import imgLogo from '../../assets/img-menuLateral/logoEscudo100x100.png'

export default function MenuLateral() {

    const history = useHistory()
    const display = useSelector((state) => state.togleClass)

    function goAtualizarDados() {
        history.push('/painel/atualizar-dados')
        history.go()
    }
    function goHome() {
        history.push('/painel/home')
        history.go()
    }
    function goAtualizarSenha() {
        history.push('/painel/atualizar-senha')
        history.go()
    }
    function goBalanceteFinanceiro() {
        history.push('/painel/balancete-financeiro')
        history.go()
    }
    function goAtasReuniao() {
        history.push('/painel/atas-reunioes')
        history.go()
    }
    function goDocClube() {
        history.push('/painel/doc-clube')
        history.go()
    }
    function goSolicitacoes() {
        history.push('/painel/solicitacoes')
        history.go()
    }

    return (
        <nav id="sidebar" className={display}>
            <div className="sidebar-header">
                <h3 className="text-center my-n2"><img className="imgLogoMenu img-fluid" src={imgLogo} alt="" /> <br /> Caxias E.C </h3>
            </div>

            <ul className="list-unstyled components">
                <li>
                    <a onClick={goHome} href="#"> <i className="fas fa-home"></i>Home</a>
                    <a href="#docSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i className="fas fa-user"></i>Perfil</a>
                    <ul className="collapse list-unstyled" id="docSubmenu">
                        <li>
                            <a onClick={goAtualizarDados} className="bgAncora" href="#">Atualizar Dados</a>
                        </li>
                        <li>
                            <a onClick={goAtualizarSenha} className="bgAncora" href="#">Alterar Senha</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#pageSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i className="fas fa-money-check-alt"></i>Financeiro</a>
                    <ul className="collapse list-unstyled" id="pageSubmenu">
                        <li>
                            <a onClick={goBalanceteFinanceiro} className="bgAncora" href="#">Balancetes Financeiros</a>
                        </li>
                        <li>
                            <a className="bgAncora" href="#">Sócio Financeiro (EM BREVE)</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a onClick={goAtasReuniao} href="#"><i className="fas fa-handshake"></i>Atas de Reuniões</a>
                </li>
                <li>
                    <a onClick={goDocClube} href="#"><i className="fas fa-file-alt"></i>Documentos do Clube</a>
                </li>
                <li>
                    <a onClick={goSolicitacoes} href="#"><i className="fas fa-address-book"></i>Solicitações ao Clube</a>
                </li>
            </ul>

            <ul className="list-unstyled CTAs">
                <li>
                    <a href="http://www.caxiasesporteclube.com.br/" className="download text-left"><i className="fas fa-mouse-pointer"></i> Visite o Site do Caxias</a>
                </li>
                <li>
                    <div className="versao">Versão:  1 . 0 . 0</div>
                </li>
            </ul>
        </nav>
    )
}