import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../../pages/Painel/index.css'
import { useHistory } from 'react-router-dom'

export default function MenuSuspenso() {

    const history = useHistory()
    const [displayBtn, setDisplayBtn] = useState('navbar-btn')
    const [displayMenu, setDisplayMenu] = useState('m-auto text-center')
    const display = useSelector(state => state.togleClass)
    const tituloMenu = useSelector(state => state.tituloMenuSusp)
    const dispatch = useDispatch()

    function logout() {
        localStorage.clear()
        history.push('/')
        history.go()

    }

    return (
        //TELA PEQUENA 
        <>
            < nav className="navbar navbar-expand-lg navbar-light bg-navbar d-sm-block d-md-none" >
                <div className="container-fluid">

                    <button onClick={() => {
                        displayMenu === 'm-auto text-center' ? setDisplayMenu('d-none') : setTimeout(() => setDisplayMenu('m-auto text-center'), 500)
                        display === '' ? dispatch({ type: 'TOGLE_ACTIVE' }) && setDisplayMenu('d-none') : dispatch({ type: 'TOGLE_NONE' })
                        displayBtn === 'navbar-btn' ? setDisplayBtn('navbar-btn active') : setDisplayBtn('navbar-btn')
                    }} type="button" id="sidebarCollapse" className={displayBtn} style={{ backgroundColor: 'rgb(124, 3, 3)' }}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>

                    <h5 className={displayMenu}> {tituloMenu} </h5>

                    <button style={{ backgroundColor: 'rgb(124, 3, 3)' }} className="btn ml-auto text-light"
                        onClick={logout} type="button">
                        Sair <i className="fas fa-sign-out-alt"></i>
                    </button>

                </div>
            </nav >

            {/*TELA GRANDE */}
            < nav className="navbar navbar-expand-lg navbar-light bg-navbar d-none d-md-block" >
                <div className="container-fluid">
                    <button onClick={() => {
                        display === '' ? dispatch({ type: 'TOGLE_ACTIVE' }) : dispatch({ type: 'TOGLE_NONE' })
                        displayBtn === 'navbar-btn' ? setDisplayBtn('navbar-btn active') : setDisplayBtn('navbar-btn')
                    }} type="button" id="sidebarCollapse" className={displayBtn}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="mx-3 text-center">
                                <h3 className="m-auto text-center"> {tituloMenu}  </h3>
                            </div>
                        </div>
                    </div>


                    <button style={{ backgroundColor: 'rgb(124, 3, 3)' }} className="btn ml-auto text-light"
                        onClick={logout} type="button">
                        Sair <i className="fas fa-sign-out-alt"></i>
                    </button>

                </div>
            </nav >
        </>
    )

}