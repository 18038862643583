import React from 'react'
import { Switch, BrowserRouter, Route } from 'react-router-dom'

//importação da páginas
import NotFound from './pages/NoFound/notFound'
import Home from './pages/Painel/Home/index'
import AtualizarDados from './pages/Painel/Perfil/AtualizarDados/index'
import AtualizarSenha from './pages/Painel/Perfil/AtualizarSenha/index'
import BalanceteFinanceiro from './pages/Painel/Financeiro/BalanceteFinanceiro/index'
import AtasReuniao from './pages/Painel/AtasReunioes/index'
import DocumentoClube from './pages/Painel/DocumentosClube/index'
import Solicitacoes from './pages/Painel/Solicitacoes/index'

import { PrivateRoute } from './auth/PrivateRoute'

export default function SubRoutes() {

    return (

        <BrowserRouter>
            <Switch>

                <PrivateRoute path="/painel/home" exact component={Home} />
                <PrivateRoute path="/painel/atualizar-dados" component={AtualizarDados} />
                <PrivateRoute path="/painel/atualizar-senha" component={AtualizarSenha} />
                <PrivateRoute path="/painel/balancete-financeiro" component={BalanceteFinanceiro} />
                <PrivateRoute path="/painel/atas-reunioes" component={AtasReuniao} />
                <PrivateRoute path="/painel/doc-clube" component={DocumentoClube} />
                <PrivateRoute path="/painel/solicitacoes" component={Solicitacoes} />
                <Route path='*' component={NotFound} />

            </Switch>
        </BrowserRouter>

    )


}