import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import './index.css'
import { baseURL } from '../../../../config/baseURL'
import { PreloaderRingMin } from '../../../../components/Preload'

export default function AtualizarSenha() {

    const history = useHistory()
    const dispath = useDispatch()
    dispath({ type: 'MENU_ALTERAR_SENHA' })

    const [alertUserSenha, setAlertUserSenha] = useState('alert alert-danger col-md-12 mt-2 d-none mb-n3')
    const [msgErro, setMsgErro] = useState('ssasasanusaiusna suiasnaiusa suiasnasauins')
    const [displayPreload, setDisplayPreload] = useState("my-auto ml-2 d-none")
    const [senha, setSenha] = useState('')
    const [repetSenha, setRepetSenha] = useState('')

    async function enviarNovaSenha(event) {
        event.preventDefault()

        setDisplayPreload("my-auto ml-2")

        if (senha !== repetSenha) {
            setDisplayPreload("my-auto ml-2 d-none")
            setMsgErro('A senha e a confirmação da senha devem ser iguais.')
            return setAlertUserSenha('alert alert-danger col-md-12 mt-2 mb-n3')
        }

        if (senha.length < 8) {
            setDisplayPreload("my-auto ml-2 d-none")
            setMsgErro('A senha deve conter no mínimo 8 dígitos')
            return setAlertUserSenha('alert alert-danger col-md-12 mt-2 mb-n3')
        }

        const response = await fetch(`${baseURL}socio-update-senha?id=${localStorage.getItem('id')}`, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                senha,
                force_senha: 'N'
            })
        })

        const resJSON = await response.json()

        if (resJSON === 0) {
            setDisplayPreload("my-auto ml-2 d-none")
            setMsgErro('Ocorreu algum erro durante a atualizaçao, tente mais tarde.')
            return setAlertUserSenha('alert alert-danger col-md-12 mt-2 mb-n3')
        }

        setDisplayPreload("my-auto ml-2 d-none")
        setMsgErro('Senha alterada com sucesso!')
        setAlertUserSenha('alert alert-success col-md-12 mt-2 mb-n3')

    }

    function cancelar() {

        history.push('/painel/home')
        history.go()

    }

    return (
        <div className="container">

            <div className="row  justify-content-center">

                <div className="col-md-8 py-5 px-3  rowPrincipal">

                    <form onSubmit={enviarNovaSenha} >
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label className="mb-2" htmlFor="nome"> <strong> Nome Completo </strong></label>
                                <input disabled style={{ borderLeftStyle: 'solid', borderLeftWidth: '3px', borderLeftColor: '#700909' }}
                                    type="text" className="form-control" id="nome" value={localStorage.getItem('nome')} />
                            </div>
                            <div className="col-md-12 mb-3">
                                <label className="mb-2" htmlFor="numFunc"> <strong> Número Funcional </strong></label>
                                <input disabled style={{ borderLeftStyle: 'solid', borderLeftWidth: '3px', borderLeftColor: '#700909' }}
                                    type="text" className="form-control" id="numFunc" value={localStorage.getItem('num-func')} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="mb-2" htmlFor="senha"> <strong> Nova Senha </strong></label>
                                <input style={{ borderLeftStyle: 'solid', borderLeftWidth: '3px', borderLeftColor: '#700909' }}
                                    type="password" className="form-control" id="senha" value={senha} onChange={event => setSenha(event.target.value)} required />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="mb-2" htmlFor="reptSenha"> <strong> Confirmar Nova Senha </strong></label>
                                <input style={{ borderLeftStyle: 'solid', borderLeftWidth: '3px', borderLeftColor: '#700909' }}
                                    type="password" className="form-control" id="reptSenha" value={repetSenha} onChange={event => setRepetSenha(event.target.value)} required />
                            </div>
                        </div>

                        <div className="row mx-0">
                            <button type="submit" style={{ backgroundColor: '#700909', }} className="btn btn-danger my-2" >Salvar</button>
                            <button type="button" onClick={cancelar} className="btn btn-secondary my-2 mx-2" >Cancelar</button>
                            <div className={displayPreload}>  <PreloaderRingMin /> </div>

                        </div>

                    </form>

                    <div className={alertUserSenha} role="alert">
                        <strong> {msgErro} </strong>
                    </div>

                </div>

            </div>

        </div>


    )
}