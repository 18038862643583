import { createStore } from 'redux'

const INITIAL_STATE = {

    togleClass: '',
    btnMenuSuspClass: 'navbar-btn',
    tituloMenuSusp: 'Home'

}

function components(state = INITIAL_STATE, action) {


    switch (action.type) {
        case 'TOGLE_ACTIVE':
            return { ...state, togleClass: 'active' }
        case 'TOGLE_NONE':
            return { ...state, togleClass: '' }
        case 'MENU_HOME':
            return { ...state, tituloMenuSusp: 'Home' }
        case 'MENU_ATUALIZAR_DADOS':
            return { ...state, tituloMenuSusp: 'Atualizar Dados' }
        case 'MENU_ALTERAR_SENHA':
            return { ...state, tituloMenuSusp: 'Alterar Senha' }
        case 'MENU_BALANCETE_FINANCEIRO':
            return { ...state, tituloMenuSusp: 'Balancete Financeiro' }
        case 'MENU_ATAS_REUNIOES':
            return { ...state, tituloMenuSusp: 'Atas de Reuniões' }
        case 'MENU_DOC_CLUBE':
            return { ...state, tituloMenuSusp: 'Documentos do Clube' }
        case 'MENU_SOLICITACOES':
            return { ...state, tituloMenuSusp: 'Solicitações' }

        default:
            return state
    }

}

const store = createStore(components)

export default store