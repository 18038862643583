import React from 'react'
import imgLogo from '../../assets/img-logo/logoEscudo100x100.png'

export default function notFound() {
    return (

        <div className="container pt-5">
            <div className="row text-center mt-2">
                <div className="col-md-12 text-center">
                    <img src={imgLogo} className="img-fluid mb-3" alt="Imagem Logo Caxias"/>
                    <h1>CAXIAS E.C Informa:</h1>
                    <h3>PÁGINA NÃO ENCONTRADA</h3>
                    <h1>Erro: 404</h1>
                </div>
            </div>
        </div>

    )
}